import React from 'react';

const FiftyFifty = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 426 288"
      className='fiftyfiftysvg'
    >
      <defs>
        <style>
          {`.cls-1 {
            fill: #d9d9d9;
          }

          .cls-1, .cls-2 {
            stroke-width: 0px;
          }

          .cls-2 {
            fill: #fff;
          }`}
        </style>
      </defs>
      <g>
        <rect className="cls-2" x="22" y="23" width="387" height="247" rx="6.45" ry="6.45"/>
        <path className="cls-1" d="M402.55,27c1.35,0,2.45,1.1,2.45,2.45v234.09c0,1.35-1.1,2.45-2.45,2.45H28.45c-1.35,0-2.45-1.1-2.45-2.45V29.45c0-1.35,1.1-2.45,2.45-2.45h374.09M402.55,19H28.45c-5.77,0-10.45,4.68-10.45,10.45v234.09c0,5.77,4.68,10.45,10.45,10.45h374.09c5.77,0,10.45-4.68,10.45-10.45V29.45c0-5.77-4.68-10.45-10.45-10.45h0Z"/>
      </g>
      <g className='desc-1'>
        <rect className="cls-1" x="40.34" y="205" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="40.34" y="216.09" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="40.34" y="227.18" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="40.34" y="238.27" width="63.1" height="6.05" rx="2.96" ry="2.96"/>
      </g>
      <g className='desc2'>
        <rect className="cls-1" x="125.8" y="205" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="125.8" y="216.09" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="125.8" y="227.18" width="73.2" height="6.05" rx="2.96" ry="2.96"/>
        <rect className="cls-1" x="125.8" y="238.27" width="63.1" height="6.05" rx="2.96" ry="2.96"/>
      </g>
      <rect className="cls-1 image" x="213" y="46" width="175" height="204" rx="1.92" ry="1.92"/>
      <g className='title'>
        <rect className="cls-1" x="40" y="54" width="159" height="27" rx="1.92" ry="1.92"/>
        <rect className="cls-1" x="40" y="88" width="159" height="27" rx="1.92" ry="1.92"/>
      </g>
    </svg>
  );
};

export default FiftyFifty;
