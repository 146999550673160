import React from 'react';

const MainHero = () => {
  return (
<svg width="426" height="288" viewBox="0 0 426 288" fill="none" xmlns="http://www.w3.org/2000/svg" className='mainherosvg'>
<path d="M402.55 23H28.45C24.8878 23 22 25.8878 22 29.45V263.55C22 267.112 24.8878 270 28.45 270H402.55C406.112 270 409 267.112 409 263.55V29.45C409 25.8878 406.112 23 402.55 23Z" fill="white"/>
<path d="M402.55 27C403.9 27 405 28.1 405 29.45V263.54C405 264.89 403.9 265.99 402.55 265.99H28.45C27.1 265.99 26 264.89 26 263.54V29.45C26 28.1 27.1 27 28.45 27H402.54M402.55 19H28.45C22.68 19 18 23.68 18 29.45V263.54C18 269.31 22.68 273.99 28.45 273.99H402.54C408.31 273.99 412.99 269.31 412.99 263.54V29.45C412.99 23.68 408.31 19 402.54 19H402.55Z" fill="#D9D9D9"/>

<g className='desc1'>
<path d="M90.6581 216.152H42.4603C41.2892 216.152 40.3398 217.102 40.3398 218.273V218.366C40.3398 219.537 41.2892 220.486 42.4603 220.486H90.6581C91.8292 220.486 92.7786 219.537 92.7786 218.366V218.273C92.7786 217.102 91.8292 216.152 90.6581 216.152Z" fill="#D9D9D9"/>
<path d="M90.6581 224.098H42.4603C41.2892 224.098 40.3398 225.047 40.3398 226.218V226.311C40.3398 227.482 41.2892 228.432 42.4603 228.432H90.6581C91.8292 228.432 92.7786 227.482 92.7786 226.311V226.218C92.7786 225.047 91.8292 224.098 90.6581 224.098Z" fill="#D9D9D9"/>
<path d="M90.6581 232.041H42.4603C41.2892 232.041 40.3398 232.99 40.3398 234.161V234.255C40.3398 235.426 41.2892 236.375 42.4603 236.375H90.6581C91.8292 236.375 92.7786 235.426 92.7786 234.255V234.161C92.7786 232.99 91.8292 232.041 90.6581 232.041Z" fill="#D9D9D9"/>
<path d="M83.4227 239.986H42.4603C41.2892 239.986 40.3398 240.936 40.3398 242.107V242.2C40.3398 243.371 41.2892 244.32 42.4603 244.32H83.4227C84.5938 244.32 85.5432 243.371 85.5432 242.2V242.107C85.5432 240.936 84.5938 239.986 83.4227 239.986Z" fill="#D9D9D9"/>
</g>

<g className='desc2'>
<path d="M151.88 216.152H103.682C102.511 216.152 101.562 217.102 101.562 218.273V218.366C101.562 219.537 102.511 220.486 103.682 220.486H151.88C153.051 220.486 154 219.537 154 218.366V218.273C154 217.102 153.051 216.152 151.88 216.152Z" fill="#D9D9D9"/>
<path d="M151.88 224.098H103.682C102.511 224.098 101.562 225.047 101.562 226.218V226.311C101.562 227.482 102.511 228.432 103.682 228.432H151.88C153.051 228.432 154 227.482 154 226.311V226.218C154 225.047 153.051 224.098 151.88 224.098Z" fill="#D9D9D9"/>
<path d="M151.88 232.041H103.682C102.511 232.041 101.562 232.99 101.562 234.161V234.255C101.562 235.426 102.511 236.375 103.682 236.375H151.88C153.051 236.375 154 235.426 154 234.255V234.161C154 232.99 153.051 232.041 151.88 232.041Z" fill="#D9D9D9"/>
<path d="M144.644 239.986H103.682C102.511 239.986 101.562 240.936 101.562 242.107V242.2C101.562 243.371 102.511 244.32 103.682 244.32H144.644C145.815 244.32 146.765 243.371 146.765 242.2V242.107C146.765 240.936 145.815 239.986 144.644 239.986Z" fill="#D9D9D9"/>
</g>

<path className='image' d="M386.08 46H214.92C213.86 46 213 46.8596 213 47.92V248.08C213 249.14 213.86 250 214.92 250H386.08C387.14 250 388 249.14 388 248.08V47.92C388 46.8596 387.14 46 386.08 46Z" fill="#D9D9D9"/>

<g className='title'>
<path d="M197.08 144H41.92C40.8596 144 40 144.86 40 145.92V169.08C40 170.14 40.8596 171 41.92 171H197.08C198.14 171 199 170.14 199 169.08V145.92C199 144.86 198.14 144 197.08 144Z" fill="#D9D9D9"/>
<path d="M197.08 178H41.92C40.8596 178 40 178.86 40 179.92V203.08C40 204.14 40.8596 205 41.92 205H197.08C198.14 205 199 204.14 199 203.08V179.92C199 178.86 198.14 178 197.08 178Z" fill="#D9D9D9"/>
</g>

<path className='videoName' d="M197.311 228H164.689C163.756 228 163 228.876 163 229.957V230.043C163 231.124 163.756 232 164.689 232H197.311C198.244 232 199 231.124 199 230.043V229.957C199 228.876 198.244 228 197.311 228Z" fill="#D9D9D9"/>
<path className='link' d="M198.565 235H163.435C163.195 235 163 235.287 163 235.64V243.36C163 243.713 163.195 244 163.435 244H198.565C198.805 244 199 243.713 199 243.36V235.64C199 235.287 198.805 235 198.565 235Z" fill="#D9D9D9"/>
</svg>
  );
};

export default MainHero;