import React from 'react';

const Video = () => {
  return (
<svg width="363" height="246" viewBox="0 0 363 246" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M342.968 19.5967H24.239C21.204 19.5967 18.7437 22.057 18.7437 25.092V224.542C18.7437 227.577 21.204 230.038 24.239 230.038H342.968C346.003 230.038 348.463 227.577 348.463 224.542V25.092C348.463 22.057 346.003 19.5967 342.968 19.5967Z" fill="white"/>
<path d="M342.968 23.0044C344.118 23.0044 345.055 23.9416 345.055 25.0918V224.534C345.055 225.684 344.118 226.621 342.968 226.621H24.2392C23.089 226.621 22.1518 225.684 22.1518 224.534V25.0918C22.1518 23.9416 23.089 23.0044 24.2392 23.0044H342.959M342.968 16.1885H24.2392C19.3232 16.1885 15.3359 20.1758 15.3359 25.0918V224.534C15.3359 229.45 19.3232 233.437 24.2392 233.437H342.959C347.875 233.437 351.863 229.45 351.863 224.534V25.0918C351.863 20.1758 347.875 16.1885 342.959 16.1885H342.968Z" fill="#D9D9D9"/>
<path d="M325.749 36.6357H38.9018C36.2386 36.6357 34.0796 38.7947 34.0796 41.458V210.731C34.0796 213.394 36.2386 215.553 38.9018 215.553H325.749C328.412 215.553 330.571 213.394 330.571 210.731V41.458C330.571 38.7947 328.412 36.6357 325.749 36.6357Z" fill="#D9D9D9"/>
<path d="M173 136.201V113.381C173 111.032 175.579 109.594 177.577 110.83L196.31 122.41C198.222 123.592 198.203 126.378 196.276 127.534L177.543 138.774C175.544 139.974 173 138.533 173 136.201Z" fill="white"/>
</svg>
  );
};

export default Video;