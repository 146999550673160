import React from 'react';

const PDFselector = () => {
  return (
    <svg width="363" height="246" viewBox="0 0 363 246" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M342.968 19.5967H24.239C21.204 19.5967 18.7437 22.057 18.7437 25.092V224.542C18.7437 227.577 21.204 230.038 24.239 230.038H342.968C346.003 230.038 348.463 227.577 348.463 224.542V25.092C348.463 22.057 346.003 19.5967 342.968 19.5967Z" fill="white"/>
    <path d="M342.968 23.0044C344.118 23.0044 345.055 23.9416 345.055 25.0918V224.534C345.055 225.684 344.118 226.621 342.968 226.621H24.2392C23.089 226.621 22.1518 225.684 22.1518 224.534V25.0918C22.1518 23.9416 23.089 23.0044 24.2392 23.0044H342.959M342.968 16.1885H24.2392C19.3232 16.1885 15.3359 20.1758 15.3359 25.0918V224.534C15.3359 229.45 19.3232 233.437 24.2392 233.437H342.959C347.875 233.437 351.863 229.45 351.863 224.534V25.0918C351.863 20.1758 347.875 16.1885 342.959 16.1885H342.968Z" fill="#D9D9D9"/>
    <path d="M87 164V89H118.014C123.635 89 128.486 90.0986 132.567 92.2959C136.673 94.4687 139.838 97.5083 142.062 101.415C144.286 105.296 145.398 109.813 145.398 114.964C145.398 120.14 144.262 124.669 141.989 128.551C139.74 132.408 136.526 135.399 132.347 137.523C128.168 139.647 123.207 140.709 117.464 140.709H98.3277V126.427H114.091C116.828 126.427 119.113 125.951 120.946 124.999C122.804 124.046 124.209 122.716 125.162 121.007C126.115 119.273 126.592 117.259 126.592 114.964C126.592 112.645 126.115 110.643 125.162 108.958C124.209 107.25 122.804 105.931 120.946 105.003C119.089 104.076 116.804 103.612 114.091 103.612H105.146V164H87Z" fill="#D9D9D9"/>
    <path d="M182.094 164H154.343V89H182.057C189.707 89 196.293 90.5015 201.816 93.5044C207.364 96.4829 211.641 100.78 214.647 106.395C217.653 111.986 219.156 118.675 219.156 126.463C219.156 134.276 217.653 140.99 214.647 146.605C211.665 152.22 207.401 156.529 201.853 159.532C196.305 162.511 189.719 164 182.094 164ZM172.489 148.546H181.397C185.601 148.546 189.157 147.838 192.065 146.422C194.998 144.981 197.209 142.65 198.7 139.427C200.216 136.18 200.973 131.859 200.973 126.463C200.973 121.068 200.216 116.771 198.7 113.573C197.185 110.35 194.949 108.031 191.992 106.615C189.059 105.174 185.442 104.454 181.141 104.454H172.489V148.546Z" fill="#D9D9D9"/>
    <path d="M229.751 164V89H281V103.722H247.897V119.103H277.737V133.861H247.897V164H229.751Z" fill="#D9D9D9"/>
    </svg>

  );
};

export default PDFselector;