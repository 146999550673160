import React from 'react';

const Text = () => {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426 288" className='textsvg'>
  <defs>
    <style>{`
      .cls-1 {
        fill: #d9d9d9;
      }

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #fff;
      }`}
    </style>
  </defs>
  <g>
    <rect className="cls-2" x="22" y="23" width="387" height="247" rx="6.45" ry="6.45"/>
    <path className="cls-1" d="M402.55,27c1.35,0,2.45,1.1,2.45,2.45v234.09c0,1.35-1.1,2.45-2.45,2.45H28.45c-1.35,0-2.45-1.1-2.45-2.45V29.45c0-1.35,1.1-2.45,2.45-2.45h374.09M402.55,19H28.45c-5.77,0-10.45,4.68-10.45,10.45v234.09c0,5.77,4.68,10.45,10.45,10.45h374.09c5.77,0,10.45-4.68,10.45-10.45V29.45c0-5.77-4.68-10.45-10.45-10.45h0Z"/>
  </g>
  <path className="cls-1" d="M40,206h348v55.34c0,3.12-2.54,5.66-5.66,5.66H45.66c-3.12,0-5.66-2.54-5.66-5.66v-55.34h0Z" transform="translate(428 473) rotate(180)"/>
  <g className='text'>
    <g>
      <rect className="cls-1" x="40" y="107.03" width="151.7" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="107" width="148.1" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="119.1" width="148.41" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="119.07" width="153.5" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="131.17" width="143.01" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="131.14" width="148.1" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="143.24" width="151.7" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="143.21" width="150.8" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="155.31" width="148.41" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="155.28" width="148.1" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="167.38" width="151.7" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="167.35" width="142.71" height="6.58" rx="2.96" ry="2.96"/>
    </g>
    <g>
      <rect className="cls-1" x="40" y="179.45" width="145.71" height="6.55" rx="2.96" ry="2.96"/>
      <rect className="cls-1" x="223.5" y="179.42" width="113.02" height="6.58" rx="2.96" ry="2.96"/>
    </g>
  </g>
  <path className="cls-1" d="M40,26h348v55.34c0,3.12-2.54,5.66-5.66,5.66H45.66c-3.12,0-5.66-2.54-5.66-5.66V26h0Z"/>
</svg>
  );
};

export default Text;
