import React from 'react';

const Title = () => {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426 288" className='titlesvg'>
  <defs>
    <style>{`
      .cls-1 {
        fill: #d9d9d9;
      }

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #fff;
      }`}
    </style>
  </defs>
  <g>
    <rect className="cls-2" x="22" y="23" width="387" height="247" rx="6.45" ry="6.45"/>
    <path className="cls-1" d="M402.55,27c1.35,0,2.45,1.1,2.45,2.45v234.09c0,1.35-1.1,2.45-2.45,2.45H28.45c-1.35,0-2.45-1.1-2.45-2.45V29.45c0-1.35,1.1-2.45,2.45-2.45h374.09M402.55,19H28.45c-5.77,0-10.45,4.68-10.45,10.45v234.09c0,5.77,4.68,10.45,10.45,10.45h374.09c5.77,0,10.45-4.68,10.45-10.45V29.45c0-5.77-4.68-10.45-10.45-10.45h0Z"/>
  </g>
  <path className="cls-1" d="M40,206h348v55.34c0,3.12-2.54,5.66-5.66,5.66H45.66c-3.12,0-5.66-2.54-5.66-5.66v-55.34h0Z" transform="translate(428 473) rotate(180)"/>
  <path className="cls-1" d="M40,26h348v55.34c0,3.12-2.54,5.66-5.66,5.66H45.66c-3.12,0-5.66-2.54-5.66-5.66V26h0Z"/>
  <g className='title'>
    <rect className="cls-1" x="90" y="116" width="246" height="27" rx="1.92" ry="1.92"/>
    <rect className="cls-1" x="135" y="150" width="159" height="27" rx="1.92" ry="1.92"/>
  </g>
</svg>
  );
};

export default Title;
